/* -------------------- */
/* -- Redeem Screens -- */
/* -------------------- */

.redeem .logo_container {
  img {
    width: 100px;
    height: auto;

    @media (min-width: 768px) {
      width: 143px;
    }
  }
}

.redeem .logo_container {
  display: flex;
  justify-content: center;
  margin: 0 0 30px 0;

  img:first-child {
    margin: 0 30px 0 0;
  }
}

.redeem {
  h2 {
    font-size: 27px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: -0.007143px;
  }

  p {
    color: #4A4A4A;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.43px;
    line-height: 30px;

    strong {
      font-weight: 700;
    }
  }

  .sp-icon {
    width: 56px;
    height: 56px;

    @media (max-width: 767px) {
      margin: 0 auto 15px auto;
      display: block;
    }

    @media (min-width: 768px) {
      float: left;
      margin: 0 15px 0 0;
    }
  }

  .sp-check-circle {
    width: 55px;
    height: 55px;
    margin: 0;
  }

  .btn-primary {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 32px;
    text-align: center;
    padding: 0 18px;
    height: 34px;
  }

  .btn-outline-secondary {
    color: #4A4A4A;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 30px;
    padding: 0;
    height: 34px;

    @media (min-width: 768px) {
      margin: 0 10px 0 0;
    }
  }

  .voucher_select {
    background: #F5F5F5;
    border: 2px solid #D6D6D6;
    border-radius: 4px;
    padding: 27px 29px;

    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }

    .voucher {
      @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 20px 0;
        text-align: center;

        &:last-of-type {
          margin: 0;
        }
      }

      @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
      }

      .sp-icon {
        margin: 0 auto 12px auto;
        float: none;

        &.sp-bookseller {
          width: 80px;
          height: 80px;
        }

        &.sp-authorised-bookseller {
          width: 80px;
          height: 80px;
        }

        &.sp-ebucks {
          width: 129px;
          height: 79px;
        }
      }

      .btn-primary {
        color: #FFFFFF;
        font-size: 15px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        padding: 3px 10px;
        height: 36px;
      }
    }
  }

  .redeem-form {
    @media (min-width: 768px) {
      display: flex;
    }

    .btn {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.5px;
      text-align: center;

      @media (min-width: 768px) {
        align-self: flex-start;
        margin: 11px 0 0 0;
      }

      @media (max-width: 767px) {
        margin: 0 auto;
        display: block;
      }
    }
  }

  .redeem-field {
    margin: 0 0 15px 0;

    @media (min-width: 768px) {
      flex-grow: 1;
      margin: 0 15px 0 0;
    }

    .form-group {
      width: 100%;
      margin: 0;

      input {
        height: 56px;
        border: 2px solid #D6D6D6;
        border-radius: 4px;
        background-color: #FFFFFF;
        padding-left: 16px;
        padding-right: 16px;
        color: #B9BABB;
        font-size: 18px;
        letter-spacing: 0.13px;
        margin: 0 !important;
      }
    }
  }

  .redeem_success_content {
    padding: 24px 29px 18px 29px;

    p {
      color: #4A4A4A;
      font-size: 15px;
      font-weight: 300;
      letter-spacing: -0.36px;
      line-height: 20px;
    }
  }

  .alert {
    padding: 11px 11px 16px 11px;

    h2 {
      color: #721C24;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
      padding: 0 0 13px 0;
      margin: 0;
    }

    p {
      color: #721C24;
      font-size: 15px;
      font-weight: 300;
      line-height: 21px;
      margin: 0;

      strong {
        font-family: Roboto-Medium,"Helvetica Neue",Helvetica,Arial,sans-serif;
        font-weight: 500;
      }
    }
  }

  .alert-primary {
    margin-bottom: 20px;

    h2, p {
      color: #5F6720;
    }
  }

  .alert-danger {
    margin: 0 0 30px 0;
  }
}

.redeem_success {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;

  .fas {
    margin: 0 10px 0 0;
    font-size: 30px;
    color: #B7D13C;
  }

  h1 {
    color: #4A4A4A;
    font-size: 27px;
    font-weight: bold;
    letter-spacing: -0.01px;
    line-height: 30px;
    margin: 0;
  }
}

/* -------------------- */
/* -------------------- */
/* -------------------- */
