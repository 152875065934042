.profile_progress {
  height: 30px;
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 0 21px 0 0;

  &.bottom_spacing {
    margin: 0 0 40px 0;
  }

  .step {
    background: #EAEAEA;
    border-top: 2px solid #FFFFFF;
    border-right: 2px solid #FFFFFF;
    border-bottom: 2px solid #FFFFFF;
    height: 30px;

    &:first-of-type {
      border-left: 2px solid #FFFFFF;
      border-radius: 4px 0px 0px 4px;
    }

    &.complete {
      background: #B7CF3B;
    }
  }

  .sp-icon {
    position: absolute;
    right: 0;
    top: -12px;
    width: 55px;
    height: 55px;
    background-size: 55px;
    background-position: center;
  }
}
