/* -------------- */
/* -- User Bar -- */
/* -------------- */

.user_bar {
  position: relative;
  width: 100%;
  height: 43px;
  margin: 20px 0;
}

.user_bar ul {
  list-style: none;
}

.user_bar .user {
  font-size: 16px;
  color: #24262A;
  font-weight: normal;
  text-transform: none;
}

.user_bar .user a {
  font-size: 16px;
  color: #24262A;
  font-weight: normal;
  text-transform: none;
}

.user_bar .user p {
  font-size: 16px;
  color: #24262A;
}

@media (min-width: 768px) {
  .user_bar .user {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
    display: block;
  }

  .user_bar .user a {
    float: left;
  }

  .user_bar .user p {
    float: left;
    margin: 0 10px 0 0;
    line-height: 40px;
  }

  .user_bar .user .user-avatar {
    margin: 0 3px;
  }

  .user_bar ul.logged-in {
    padding: 0;
  }
}

/* -------------- */
/* -------------- */
/* -------------- */
