.customer-landing {
  .content {
    padding: 48px 0 24px 0;
  }

  .container {
    max-width: 640px;
    text-align: center;
  }

  .inner_content {
    padding: 40px 17px 40px 17px;

    .section_heading {
      margin: 0 0 24px 0;

      .image_block {
        margin: 0 0 24px 0;
      }

      img {
        width: 208px;
        height: 100%;
        object-fit: contain;
        margin: 0 0 24px 0;
      }

      h2 {
        font-size: 36px;
        line-height: 42px;
        margin: 0 0 4px 0;
        font-weight: 600;
      }

      h3 {
        font-size: 30px;
        line-height: 35px;
        margin: 0 0 4px 0;
        font-weight: 300;
      }
    }

    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #767676;
      margin: 0 0 8px 0;
    }

    .btn-lg {
      background: rgba(183, 207, 59, 1);
      max-width: 220px;
      margin: 0 0 8px 0;
      color: #fff;
      height: 36px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
    }

    .btn-learn-more {
      color: #4A4A4A;
    }

    .btn-invite-code {
      color: #fff;
      background: rgba(108, 148, 62, 1);
    }

    .advanced-filter {
      max-width: 679px;
      margin: 0 auto 16px auto;
    }
  }
}

.assistance {
  padding: 24px 0 0 0;

  .container {
    padding: 16px 17px;
  }

  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #4A4A4A;
    margin: 0 0 12px 0;
  }

  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #767676;
    margin: 0 auto 20px auto;
    max-width: 550px;
  }

  hr {
    max-width: 400px;
    border: 1px solid #BCBEC0;
    margin: 0 auto 20px auto;
  }

  .assistance-block {
    display: inline-block;
    margin: 0 11px 0 0;

    &:last-child {
      margin: 0;
    }

    .inner-assistance {
      display: flex;
      align-items: center;
    }

    .sp-icon {
      margin: 0 14px 0 0;

      &.sp-heart {
        background-position: center left;
      }
    }

    p {
      font-size: 15px;
      color: #6C943E;
    }

    a {
      text-decoration: none;
    }
  }
}

.customer-group {
  .section_heading {
    .sp-icon {
      width: 100px;
      height: 100px;
      margin: 0 0 24px 0;
    }
  }

  .inner_content {
    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      color: #767676;
    }

    form {
      margin: 0 0 24px 0;
      width: 100%;
      height: 61px;
    }
  }
}

.invitation {
  .content {
    padding: 48px 0 24px 0;
  }

  .container {
    max-width: 779px;
    text-align: left;
  }

  .assistance {
    .container {
      max-width: 640px;
      text-align: center;
    }
  }

  .inner_content {
    padding: 40px 17px 40px 17px;

    .content_col {
      display: flex;

      .icon {
        margin: 0 24px 0 0;
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .section_heading {
      margin: 0 0 24px 0;

      .image_block {
        margin: 0 0 24px 0;
      }

      img {
        width: 208px;
        height: 100%;
        object-fit: contain;
        margin: 0 0 24px 0;
      }

      h2 {
        font-size: 30px;
        line-height: 25px;
        margin: 0;
        font-weight: 600;
      }
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      color: #767676;
      margin: 0 0 24px 0;
    }

    .btn-lg {
      background: rgba(183, 207, 59, 1);
      max-width: 220px;
      margin: 0 0 8px 0;
      color: #fff;
      height: 36px;
    }
  }
}
