.success-container {
  max-width: 520px;
}

.pay {
  &.success {
    text-align: center;

    h2 {
      font-weight: bold;
      font-size: 30px;
      line-height: 35px;
      color: #58595B;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #767676;
    }

    img {
      margin: 0 0 24px 0;
    }

    .total {
      padding: 16px 0 !important;
      border-top: 1px solid #EAEAEA;
      text-align: left;
    }
  }

  .container-header {
    padding: 16px 24px 16px 24px;
    background: #F4F4F4;
    width: 100%;
    display: flex;
    align-items: center;

    .sp-icon {
      width: 24px;
      height: 24px;
      margin: 0 8px 0 0;
    }

    h2 {
      font-size: 24px;
      line-height: 28px;
      margin: 0;
    }
  }

  .inner_content {
    padding: 20px 24px;

    p {
      font-size: 16px;
    }
  }

  .total {
    padding: 0 0 16px 0;
    margin: 0 0 16px 0;
    border-bottom: 1px solid #EAEAEA;
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .totalDetails {
      flex: 1;
    }
    .totalAmounts {
      display: flex;
      align-items: flex-end;
    }

    p {
      margin: 0;
      line-height: 18px;

      &.orderRef {
        padding-top: 8px;
        span {
          color: rgba(106, 146, 66, 1);
          font-weight: bold;
        }
      }

      &.orderTotal {
        font-size: 24px;
        display: flex;
        align-items: flex-end;
        margin-top: 0.5em;
        span {
          font-weight: 700;
          color: rgba(183, 207, 59, 1);
        }
      }
    }
  }

  .alert-info {
    display: flex;
    padding: 12px;
    align-items: center;
    background: rgba(62, 146, 204, 0.3);
    border: 1px solid rgba(62, 146, 204, 0.2);
    margin-bottom: 0;

    .sp-icon {
      width: 24px;
      height: 24px;
      margin: 0 10px 0 0;
    }

    span {
      font-size: 15px;
      color: #3E92CC;
    }
  }

  label {
    margin: 0;

    span {
      color: #6A9242;
    }
  }

  .radioBox {
    padding: 24px;
    margin: 0 0 8px 0;
    border: 1px solid #BCBEC0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    label {
      font-size: 14px;
      color: rgba(154, 154, 154, 1);
    }

    input.radio {
      width: 20px;
      height: 20px;
      margin: 0 16px 0 0;
    }

    .logos {
      margin-left: auto;

      .snapplify {
        height: 20px;
        width: auto;
      }

      .visa {
        height: 15px;
        width: auto;
      }

      .mastercard {
        height: 20px;
        width: auto;
      }

      .american_express {
        height: 14px;
        width: auto;
      }

      .diners_club {
        height: 20px;
        width: auto;
      }

      .mpesa {
        height: 18px;
        width: auto;
      }

      .ozow {
        height: 19px;
        width: auto;
      }

      .pay_u {
        height: 20px;
        width: auto;
      }

      .standard_bank {
        height: 15px;
        width: auto;
        margin: 0 8px 0 0;
      }

      .fnb {
        height: 15px;
        width: auto;
        margin: 0 8px 0 0;
      }

      .absa {
        height: 15px;
        width: auto;
      }

      .nedbank {
        height: 15px;
        width: auto;
      }

      .capitec {
        height: 15px;
        width: auto;
      }
    }

    .paymentOption {
      display: none;
      flex: 1 0 100%;
      margin: 24px 0 0 0;

      p {
        font-size: 14px;
        line-height: 16px;
      }

      .paymentButtons {
        width: 100%;
        text-align: center;
      }

      .btn-white {
        background: #FFFFFF;
        border: 1px solid #F4F4F4;
        box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 4px 6px -1px rgba(15, 23, 42, 0.1), 0px 2px 4px -1px rgba(15, 23, 42, 0.06);
        border-radius: 4px;
        margin: 0 24px 0 0;
      }

      &.eftShow {
        span {
          font-size: 14px;
          text-transform: uppercase;
          color: #9A9A9A;
        }

        p {
          margin: 0;
          line-height: 24px;
        }

        .heading {
          font-size: 16px;
          margin: 0 0 8px 0;
          line-height: 24px;
        }
      }

      .alert-info {
        background: #F4F4F4;
        border-radius: 4px;
        border: none;

        span {
          color: rgba(88, 89, 91, 1);

          &.credit {
            margin-left: auto;
          }
        }

        img {
          width: 20px;
          height: auto;
          margin: 0 4px 0 0;
        }
      }

      .alert-warning {
        background: rgba(241, 244, 217, 1);
        margin: 16px 0 0 0;
        display: inline-block;
        padding: 12px;

        .sp-icon {
          width: 24px;
          height: 24px;
          margin: 0 10px 0 0;
          float: left;
        }

        span.text {
          font-size: 12px;
          line-height: 24px;
          color: rgba(95, 102, 37, 1);
          text-transform: none;
          width: calc(100% - 39px);
          float: left;

          a {
            font-size: 12px;
            color: rgba(95, 102, 37, 1);
            font-weight: bold;
          }
        }
      }
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    input {
      margin: 0 8px 0 0;
      width: 20px;
      height: 20px;
    }

    label {
      font-size: 12px;
      width: calc(100% - 32px);
      line-height: 14px;

      &.error {
        margin: 0 0 0 28px;
      }

      a {
        font-size: 12px;
        color: rgba(183, 207, 59, 1);
      }
    }
  }
}
