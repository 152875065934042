.icon_heading {
  display: flex;
  align-items: center;

  .sp-icon {
    margin: 0 20px;
  }
}

.persona_container {
  display: flex;
  justify-content: center;
  margin: 1em -1em 0 -1em;
}

.persona_block {
  background-color: #F4F4F4;
  padding: 25px 25px;
  text-decoration: none;
  color: #000;
  width: 100%;
  min-height: 186px;
  margin: 0 0 30px 0;
  display: block;
  text-align: left;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  }

  h3 {
    font-weight: 500;
    font-size: 24px;
    font-weight: normal;
    color: #222628;
    margin: 0 0 24px 0;

    i {
      color: #B7D13B;
      font-size: 0.8em;
      float: right;
    }

    a {
      font-size: 24px;
      color: #222628;
    }
  }

  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #222628;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.5em 0;
  }
}
